import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/HeroService"
import { useStaticQuery, graphql } from "gatsby"
import Works from "../components/Works"
import Question from "../components/Question"
import ImgTextLeftServices from "../components/ImgTextLeftServices"
import ImgTextRightServices from "../components/ImgTextRightServices"

const Bathroom = () => {
    const { wpPage } = useStaticQuery(graphql`
    {
        wpPage(title: {eq: "Flat roof"}) {
            flatroofservice {
              firstServiceHeading
              firstServiceImgAlt
              firstServiceTag
              firstServiceText
              secondServiceHeading
              secondServiceImg {
                altText
                sourceUrl
              }
              secondServiceImgAlt
              secondServiceTag
              secondServiceText
              sliderText
              firstServiceImg {
                altText
                sourceUrl
              }
            }
          }
    }
  `)
    return (
  <Layout>
    <div className="service-page">
      <Hero active="bathroom" herotitle="Flat Roof" herotext={wpPage.flatroofservice.sliderText} ></Hero>
      <ImgTextLeftServices headingtag={wpPage.flatroofservice.firstServiceTag} heading={wpPage.flatroofservice.firstServiceHeading} text={wpPage.flatroofservice.firstServiceText} obrazek={wpPage.flatroofservice.firstServiceImg.sourceUrl} obrazekalt={wpPage.flatroofservice.firstServiceImg.altText}></ImgTextLeftServices>
      <ImgTextRightServices  headingtag={wpPage.flatroofservice.secondServiceTag} heading={wpPage.flatroofservice.secondServiceHeading} text={wpPage.flatroofservice.secondServiceText} obrazek={wpPage.flatroofservice.secondServiceImg.sourceUrl} obrazekalt={wpPage.flatroofservice.secondServiceImg.altText}></ImgTextRightServices>
      <Works></Works>   
      <Question></Question>
    </div>
  </Layout>  
    )
    }

export default Bathroom;
 